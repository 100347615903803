import React from "react";
import logodental from "../components/images/logodental.webp";
import heroimg from "../components/images/dentalwebsitecopy.webp";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Finalnav from "./Homepages/Finalnav";
import { Helmet } from "react-helmet";

function Dentalguru() {
  const handleFooterLink = () => {
    window.scrollTo(0, 0);
  };
  return (
    <Wrapper>
      <Finalnav />
      <Helmet>
        <title>
        Empower Your Dental Practice with Dental Guru | Comprehensive Software for Dentists & Clinics
        </title>
        <meta
          name="description"
          content="Enhance efficiency and streamline operations in your dental practice with Dental Guru. Our comprehensive software solution is designed to meet the unique needs of dental doctors and clinics, offering tools for appointment scheduling, patient management, billing, and more. Discover how Dental Guru can revolutionize your practice today!
          "
        />
        <link rel="canonical" href="https://doaguru.com/dental-guru" />
      </Helmet>
      <div className="container-fluid mx-2">
        <img src={logodental} alt="" />
        <h2 style={{ color: "#4cd3d9" }}>Dental Clinic Management</h2>
        <h1>
          We Care About Your Clinic <div className="underline"></div>
        </h1>

        <div className="container  my-5">
          <div className="row">
            <div className="col-lg-6 col-md-6" id="mobile">
              <img id="clientpages6" src={heroimg} className="" />
            </div>
            <div className="col-lg-6 col-md-6">
              <ul className=" d-flex flex-column gap-3 h5 mt-3">
                <li>Patient Appointment</li>
                <li>Smart Diagnosis system</li>
                <li>Smart prescription system</li>
                <li>Staff Management</li>
                <li>Patient Data Management</li>
                <li>Account Management</li>
                <li>Inventory Management</li>
                <li>Multi Branch Management</li>
              </ul>
            </div>
          </div>
          <Link
            to="/contact_us"
            onClick={handleFooterLink}
            className="btn mt-4 mx-5 "
            style={{ backgroundColor: " #FE7604", color: "#fff" }}
            id="btn6"
          >
            Book Now
          </Link>

          <h2 className="mt-4 " style={{ color: "#4cd3d9" }}>
            FREE 15 DAY TRIAL
          </h2>
        </div>
      </div>
    </Wrapper>
  );
}

export default Dentalguru;
const Wrapper = styled.div`
  .underline {
    height: 4px;
    width: 33rem;
    background-color: #4cd3d9;
    margin-top: 2;
    margin-bottom: 10;
    @media screen and (max-width: 768px) {
      width: 22rem;
    }
  }
`;
