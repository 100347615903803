import styled from "styled-components";
import blogbg from "../components/images/blogbg.webp";
import { Link } from "react-router-dom";
import bloghero from "../components/images/bloghero1.webp";
import Finalnav from "./Homepages/Finalnav";
import Breadcrumb from "../components/Breadcrumb";
import { useEffect } from "react";
import AOS from "aos";
import { BiCategoryAlt } from "react-icons/bi";
import { CgCalendarDates } from "react-icons/cg";
import blog1 from '../components/images/blog.jpeg'
import blog2 from '../components/images/blogimg2.webp'
import blog3 from '../components/images/blogimg3.webp'
import blog4 from '../components/images/blog4.webp'
import blog5 from '../components/images/blog5.webp'
import blog6 from '../components/images/blog6.webp'
import blog7 from '../components/images/blogsimage.jpg'
import blog8 from '../components/images/performblog.png'
import blog9 from '../components/images/blog9.jpg'
import blog10 from '../components/images/blog10.jpg'
import blog11 from '../components/images/Blog11.png'
import blog12 from '../components/images/Blog12.jpg'
import Footer from "./Homepages/Footer";
import { Helmet } from "react-helmet";


function Blogs() {
  useEffect(() => {
    AOS.init(); // Initialize AOS
  }, []);
  const handleFooterLink = () => {
    window.scrollTo(0, 0);
  };
  
  return (
    <>
      <Finalnav />
      <Helmet>
        <title>
        Insightful Blog Articles on Software Solutions & Digital Marketing Strategies

        </title>
        <meta
          name="description"
          content="Stay updated with the latest trends and expert advice on software development, digital marketing strategies, and tech innovations. Explore informative articles on our blog at DOAGuru Infosystems. "
        />
        <link rel="canonical" href="https://doaguru.com/blogs" />
      </Helmet>
      <Breadcrumb />
      <Wrapper>


        <div
          className="container-fluid"
          id="cont7"
          style={{
            backgroundImage: `url(${blogbg})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <div className="row" id="row67">
            <div className="col-lg-6 col-md-12" style={{ marginTop: "8rem" }}>
             
              <h1 className="ws text-white" style={{ marginBottom: "1rem" }}>
              Unlocking Digital Success with our Digital Marketing Blogs! 
              </h1>

              <p className="pdd text-white mt-4">
              Discover insights and trends at DoaGuru InfoSystems. Elevate your digital strategies with our expert content.

              </p>
              <button
                type="button"
                className="btn  mt-4"
                style={{ color: "white" }}
              >
                <Link
                  to="/contact_us"
                  onClick={handleFooterLink} className="text-white text-decoration-none"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  {" "}
                  Get Started
                </Link>
              </button>
            </div>
            <div className="col-lg-6 col-md-12" id="heroimg">
              <img
                src={bloghero}
                className="img3 d-block "
                alt="..."
                
              />
            </div>
          </div>
        </div>
        <div className="container"style={{ maxHeight: "700px", overflowY: "auto" }} >
        <h2 className="text-center mt-4">List of Blogs</h2>
          <div
              className="underline mx-auto"
              style={{
                height: 3,
                width: "4rem",
                backgroundColor: "#34495E",
                marginTop: 10,
                marginBottom: 10,
              }}
            ></div>
          <div className="row mt-5 cardBox">
        
            <div className="col-12 col-lg-4 col-md-6 mb-4 ">
                <Link to="/10-Essential-Skills-Every-Digital-Marketer-Needs-to-Know" onClick={handleFooterLink} className="text-white text-decoration-none">
              <div className="card shadow p-3 mb-5 bg-white rounded">
                  {" "}
                  <img
                    src={blog1}
                    className="card-img-top"
                    alt="..."
                  />{" "}
            
                <div className="card-body">
                 
                  <h5 className="card-title mt-2">
                  10 Essential Skills Every Digital Marketer Needs to Know
                  </h5>
                  <p className="card-text">
                  Unlock the secrets to success in the digital marketing arena with our comprehensive guide. Explore the core skills essential for every digital marketer to thrive. From SEO mastery to content strategy, dive into the 10 fundamental skills that will propel your digital marketing expertise to new heights.

                  </p>
                  <p className="card-text">
                   
                  </p>
                </div>
              </div>
              </Link>
            </div>
            <div className="col-12 col-lg-4 col-md-6 mb-4">
                <Link to="/Optimizing-Your-Business-Because-Google-Can't-Read-Your-Mind-Yet" onClick={handleFooterLink} className="text-white text-decoration-none">
              <div className="card shadow p-3 mb-5 bg-white rounded">
                  {" "}
                  <img
                  src={blog2}
                    className="card-img-top"
                    alt="..."
                    height={255}
                    // id="fullscreen2"
                  />{" "}
             
                <div className="card-body">
                 
                  <h5 className="card-title mt-2">
                  Optimizing Your Business: Because Google Can't Read Your Mind... Yet
                  </h5>
                  <p className="card-text">
                  Enhance your business's digital presence with strategic optimization techniques. From SEO to user-friendly design, we'll ensure your online visibility speaks the language of success, even before Google can read your mind.

                  </p>
                  <p className="card-text">
                   
                  </p>
                </div>
              </div>
              </Link>
            </div>
            <div className="col-12 col-lg-4 col-md-6 mb-4">
                <Link to="/Email-Marketing-Because-Your-Inbox-Was-Feeling-Lonely" onClick={handleFooterLink} className="text-white text-decoration-none">
              <div className="card shadow p-3 mb-5 bg-white rounded">
                  {" "}
                  <img
                 src={blog3}
                    className="card-img-top"
                    alt="..."
                    id="fullscreen1"
                    
                  />{" "}
             
                <div className="card-body">
                 
                  <h5 className="card-title mt-2">
                  Email Marketing: Because Your Inbox Was Feeling Lonely
                  </h5>
                  <p className="card-text">
                  Dive into engaging articles that breathe life into your inbox, offering strategies to boost engagement, craft compelling content, and maximize the potential of this powerful marketing tool. Join us on this digital journey to make your emails stand out in the crowd!

                  </p>
                  <p className="card-text">
                   
                  </p>
                </div>
              </div>
              </Link>
            </div>
            <div className="col-12 col-lg-4 col-md-6 mb-4">
                <Link to="/Unveiling-the-Secrets-of-the-Best-Graphic-Designing-Company-for-Your-Creative-Dreams!" onClick={handleFooterLink} className="text-white text-decoration-none">
              <div className="card shadow p-3 mb-5 bg-white rounded">
                  {" "}
                  <img
                  src={blog4}
                    className="card-img-top"
                    alt="..."
                    id="fullscreen"
                  />{" "}
               
                <div className="card-body">
                 
                  <h5 className="card-title mt-2">
                  Unveiling the Secrets of the Best Graphic Designing Company for Your
            Creative Dreams!
                  </h5>
                  <p className="card-text">
                  Discover our vibrant blog - a treasure trove of insights, tips, and trends in the world of email marketing. Dive into engaging articles that breathe life into your inbox, offering strategies to boost engagement, craft compelling content, and maximize the potential of this powerful marketing tool.

                  </p>
                  <p className="card-text">
                   
                  </p>
                </div>
              </div>
              </Link>
            </div>
            <div className="col-12 col-lg-4 col-md-6 mb-4">
                <Link to="/Best-Graphic-Designing-Company-in-Jabalpur-Elevate-Your-Brand-Presence" onClick={handleFooterLink} className="text-white text-decoration-none">
              <div className="card shadow p-3 mb-5 bg-white rounded">
                  {" "}
                  <img
                 src={blog5}
                    className="card-img-top"
                    alt="..."
                    id="fullscreen3"
                  />{" "}
               
                <div className="card-body">
                 
                  <h5 className="card-title mt-2">
                  Best Graphic Designing Company in Jabalpur: Elevate Your Brand
            Presence
                  </h5>
                  <p className="card-text">
                  Elevate your brand to new heights with the best graphic designing company in Jabalpur. Our expert team combines creativity and precision to craft visually stunning designs that leave a lasting impression. From captivating logos to eye-catching marketing collateral, we specialize in bringing your brand to life. 
                  </p>
                  <p className="card-text">
                   
                  </p>
                </div>
              </div>
              </Link>
            </div>
            <div className="col-12 col-lg-4 col-md-6 mb-4">
                <Link to="/Cybersecurity-Essentials-Protecting-Your-Business-in-a-Digital-Age" onClick={handleFooterLink} className="text-white text-decoration-none">
              <div className="card shadow p-3 mb-5 bg-white rounded">
                  {" "}
                  <img
                 src={blog6}
                    className="card-img-top"
                    alt="..."
                    id="fullscreen3"
                  />{" "}
               
                <div className="card-body">
                 
                  <h5 className="card-title mt-2">
                  Cybersecurity Essentials: Protecting Your Business in a Digital Age

                  </h5>
                  <p className="card-text">
                  In today's era the need, for cybersecurity measures is more crucial than ever before. As businesses increasingly rely on platforms the risk of cyber threats becomes a concern. DOAGuru Infosystems, renowned as the best digital marketing company in Jabalpur understands 
                  </p>
                  <p className="card-text">
                   
                  </p>
                </div>
              </div>
              </Link>
            </div>
            <div className="col-12 col-lg-4 col-md-6 mb-4">
                <Link to="/The-Future-of-SEO:-Predictions-and-Strategies-for-2024" onClick={handleFooterLink} className="text-white text-decoration-none">
              <div className="card shadow p-3 mb-5 bg-white rounded">
                  {" "}
                  <img
                 src={blog7}
                    className="card-img-top"
                    alt="..."
                    id="fullscreen3"
                  />{" "}
               
                <div className="card-body">
                 
                  <h5 className="card-title mt-2">
                  The Future of SEO: Predictions and Strategies for 2024

                  </h5>
                  <p className="card-text">
                  In the dynamic world of digital marketing, peering into the future to anticipate trends and adapt strategies is crucial for staying ahead. As we approach 2024, the landscape of search engine optimization (SEO) is on the brink of significant evolution. 

                  </p>
                  <p className="card-text">
                   
                  </p>
                </div>
              </div>
              </Link>
            </div>

                <div className="col-12 col-lg-4 col-md-6 mb-4">
                <Link to="/Performance-Marketing-vs-Digital-Marketing:-Understanding-the-Key-Differences" onClick={handleFooterLink} className="text-white text-decoration-none">
              <div className="card shadow p-3 mb-5 bg-white rounded">
                  {" "}
                  <img
                 src={blog8}
                    className="card-img-top"
                    alt="..."
                    id="fullscreen3"
                  />{" "}
               
                <div className="card-body">
                 
                  <h5 className="card-title mt-2">
                  Performance Marketing vs. Digital Marketing: Understanding the Key Differences



                  </h5>
                  <p className="card-text">
                  In today's world of online business, companies are always looking for ways to make the most of their online presence and achieve tangible results. Two strategies that often come into play are performance marketing 

                  </p>
                  <p className="card-text">
                   
                  </p>
                </div>
              </div>
              </Link>
            </div>
                <div className="col-12 col-lg-4 col-md-6 mb-4">
                <Link to="/Best-SEO-company-in-Madhya-pradesh" onClick={handleFooterLink} className="text-white text-decoration-none">
              <div className="card shadow p-3 mb-5 bg-white rounded">
                  {" "}
                  <img
                 src={blog9}
                    className="card-img-top"
                    alt="..."
                    id="fullscreen3"
                  />{" "}
               
                <div className="card-body">
                 
                  <h5 className="card-title mt-2">
                    Boost Your Online Presence: Best SEO Company in Madhya Pradesh
                  </h5>
                  <p className="card-text">
                  In today's era establishing an online presence is essential, for any business looking to succeed in the competitive market environment. With websites competing for attention, how can yours make a mark? The key lies in SEO (Search Engine Optimization) 

                  </p>
                  <p className="card-text">
                   
                  </p>
                </div>
              </div>
              </Link>
            </div>
            {/* Blog 10 card  */}
                <div className="col-12 col-lg-4 col-md-6 mb-4">
                <Link to="/The-Best-IT-Company-in-Jabalpur-DOAGuru-Infosystems" onClick={handleFooterLink} className="text-white text-decoration-none">
              <div className="card shadow p-3 mb-5 bg-white rounded">
                  {" "}
                  <img
                 src={blog10}
                    className="card-img-top"
                    alt="..."
                    id="fullscreen3"
                  />{" "}
               
                <div className="card-body">
                 
                  <h5 className="card-title mt-2">
                  The Best IT Company in Jabalpur - DOAGuru Infosystems




                  </h5>
                  <p className="card-text">
                  In the bustling realm of Information Technology within Jabalpur, there’s one name that resonates with excellence and innovation - <Link
                      to="https://doaguru.com/"
                      target="_blank"
                      className="text-decoration-none"
                    >DOAGuru Infosystems</Link>. Established with a vision to redefine software solutions, DOAGuru has solidified its position as the <Link
                    to="https://doaguru.com/"
                    target="_blank"
                    className="text-decoration-none"
                  >leading IT company in Jabalpur</Link>.
                  
                  </p>
                  <p className="card-text">
                   
                  </p>
                </div>
              </div>
              </Link>
            </div>
            {/* Blog 11 card  */}
            <div className="col-12 col-lg-4 col-md-6 mb-2">
                <Link to="/Best-Software-Development-Company-in-Jabalpur" onClick={handleFooterLink} className="text-white text-decoration-none">
              <div className="card shadow p-3 mb-5 bg-white rounded">
                  {" "}
                  <img
                 src={blog11}
                    className="card-img-top rounded-2"
                    alt="..."
                    id="fullscreen3"
                  />{" "}
               
                <div className="card-body">
                 
                  <h5 className="card-title mt-1">

                  Best Software Development Company in Jabalpur: DOAguru Infosystems

                  </h5>
                  <p className="card-text">
                  Welcome to <Link
                    to="https://doaguru.com/contact_us"
                    target="_blank"
                    className="text-decoration-none"
                  >DOAguru Infosystems</Link>, recognized as the <Link
                  to="https://doaguru.com/"
                  target="_blank"
                  className="text-decoration-none"
                >best software company in Jabalpur</Link>. In this blog, we'll explore why DOAguru Infosystems stands out as the <Link
                to="https://doaguru.com/"
                target="_blank"
                className="text-decoration-none"
              >leading software development firm in Jabalpur</Link> and how we can help businesses like yours thrive in the digital age.          
                  {/* </p>
                  <p className="card-text"> */}
                   
                  </p>
                </div>
              </div>
              </Link>
            </div>
            {/* Blog 12 card  */}
            <div className="col-12 col-lg-4 col-md-6 mb-2">
                <Link to="/Best-Digital-Marketing-Company-in-Madhya-Pradesh" onClick={handleFooterLink} className="text-white text-decoration-none">
              <div className="card shadow p-3 mb-5 bg-white rounded">
                  {" "}
                  <img
                 src={blog12}
                    className="card-img-top rounded-2"
                    alt="..."
                    id="fullscreen3"
                  />{" "}
               
                <div className="card-body">
                 
                  <h5 className="card-title mt-1">

                  The Best Digital Marketing Company in Madhya Pradesh

                  </h5>
                  <p className="card-text">
                  At DOAGuru Infosystems, we pride ourselves on being  <Link
                    to="https://doaguru.com/"
                    target="_blank"
                    className="text-decoration-none"
                  >the best digital marketing company in Madhya Pradesh</Link>. Our commitment to excellence, innovative strategies, and customer-centric approach make us the preferred choice for businesses looking to enhance their online presence and achieve remarkable growth.
                   
                  </p>
                </div>
              </div>
              </Link>
            </div>
          </div>
        </div>
        <Footer />
      </Wrapper>
    </>
  );
}

export default Blogs;

const Wrapper = styled.div`
  #cont7 {
    height: 25rem;

    @media screen and (max-width: 768px) {
      height: 45rem;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      height: 48rem;
    }
  }
  #row67 {
    margin-left: 8rem;

    @media screen and (max-width: 768px) {
      margin-left: 0rem;
    }
  }
  button {
    background-color: #fe7604;
  }
  button:hover {
    background-color: #fe7604;
  }
  .img3 {
    margin-top: 1rem;
    margin-left: -2rem;
    @media screen and (max-width: 768px) {
      margin-left: -8rem;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      height: 30rem;
      margin-left: -1rem;
      margin-top: -3rem;
    }
  }

  h2 {
    font-size: 34px;
    color: #1e1666;
    @media screen and (max-width: 768px) {
      font-size: 1.4rem;
    }
  }

  p {
    color: #5a626d;
    text-align: justify;
    @media screen and (max-width: 768px) {
      font-size: 1rem;
    }
  }
  h5 {
    font-size: 20px;
    color: #4034db; //card
    margin-top: 2rem;
    @media screen and (max-width: 768px) {
      font-size: 1.2rem;
    }
  }
  .frontimg {
    width: 100%;
    height: 40rem;
    @media screen and (max-width: 768px) {
      height: 15rem;
      width: 100%;
    }
     @media screen and (min-width: 768px) and (max-width: 1020px) {
      height: 30rem;
      margin-left: -1rem;
      margin-top: -3rem;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      height: 20rem;
      width: 100%;
    }
  }
  #more {
    width: 10rem;
    margin: 0.5rem;
    color: white;
  }
  .frontimg1 {
    width: 100%;
    height: 40rem;
    @media screen and (max-width: 768px) {
      height: 15rem;
      width: 100%;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      height: 20rem;
      width: 100%;
    }
  }
  .card {
    border: none;
    margin: 1rem;

    img {
      border-radius: 10px;
      transition: 0.4s ease-in-out;
      cursor: pointer;
    }
    img:hover {
      transform: scale(1.09);
    }

    .card-body {
      padding-left: 0;
      padding-right: 0;
    }
  }
  #fullscreen1{
  height: 14.7rem;
  @media screen and (min-width: 768px) and (max-width: 1020px) {
     height: 14rem;
    }
  @media screen and (min-width: 1020px) and (max-width: 1600px) {
      height: 14.5rem;
    
    }
  }
  #fullscreen{
  height: 14.8rem;
  @media screen and (min-width: 768px) and (max-width: 1020px) {
     height: 11rem;
    }
  @media screen and (min-width: 1020px) and (max-width: 1600px) {
      height: 12rem;
    
    }
  }
  #fullscreen2{
  height: 16.1rem;
  @media screen and (min-width: 768px) and (max-width: 1020px) {
     height: 14rem;
    }
  @media screen and (min-width: 1020px) and (max-width: 1600px) {
      height: 16.2rem;
    
    }
  }
  #fullscreen3{
  height: 15rem;
  @media screen and (min-width: 768px) and (max-width: 1020px) {
     height: 11rem;
    }
  @media screen and (min-width: 1020px) and (max-width: 1600px) {
      height: 12rem;
    
    }
  }
  .card-text{
  padding: 5px;
}

`;
